
body {
    position: relative;
    margin: 0;
  }
  
  img {
    user-select: none;
    pointer-events: none;
  }
 
 *,
 ::after,
 ::before {
     box-sizing: border-box;
 }
 
 blockquote,
 dl,
 dd,
 h1,
 h2,
 h3,
 h4,
 h5,
 h6,
 hr,
 figure,
 p,
 pre {
     margin: 0;
 }
 
 h1,
 h2,
 h3,
 h4,
 h5,
 h6 {
     font-size: inherit;
     font-weight: inherit;
 }
 
 ol,
 ul {
     list-style: none;
     margin: 0;
     padding: 0;
 }
 
 img,
 svg,
 video,
 canvas,
 audio,
 iframe,
 embed,
 object {
     display: block;
 }

 input {
    appearance: none;
    border: none;
    background-color: transparent;
    font-family: inherit;
    font-size: inherit;
    padding: 0;
    margin: 0;
    outline: none;
    box-shadow: none;
    box-sizing: border-box;
  }
  
  /* Apply desired styles */
  input {
    /* Add your custom styles here */
    /* For example: */
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    width: 100%;
  }
  
  /* Handle browser-specific styles */
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  input[type="checkbox"]:focus {
    outline: inherit;
    outline-offset: inherit;
    --tw-ring-inset: inherit;
    --tw-ring-offset-width: inherit;
    --tw-ring-offset-color: inherit;
    --tw-ring-color: inherit;
    --tw-ring-offset-shadow: inherit;
    --tw-ring-shadow: inherit;
    box-shadow: inherit;
  }

  input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    background-color: transparent;
    font-family: inherit;
    font-size: inherit;
    padding: 0;
    margin: 0;
    outline: none;
    box-shadow: none;
    box-sizing: border-box;
    color: inherit;
    user-select: inherit;
    flex-shrink: inherit;
    height: inherit;
    width: inherit;
  }
 
 /* Document
    ========================================================================== */
 
 /**
  * 1. Correct the line height in all browsers.
  * 2. Prevent adjustments of font size after orientation changes in iOS.
  */
 
 html {
     line-height: 1.15; /* 1 */
     -webkit-text-size-adjust: 100%; /* 2 */
 }
 
 /* Sections
      ========================================================================== */
 
 /**
    * Remove the margin in all browsers.
    */
 
 body {
     margin: 0;
 }
 
 /**
    * Render the `main` element consistently in IE.
    */
 
 main {
     display: block;
 }
 
 /* Text-level semantics
      ========================================================================== */
 
 /**
    * Remove the gray background on active links in IE 10.
    */
 
 a {
     background-color: transparent;
     text-decoration: none;
     color: inherit;
 }
 
 /* Embedded content
      ========================================================================== */
 
 /**
    * Remove the border on images inside links in IE 10.
    */
 
 img {
     border-style: none;
 }
 
 /* Forms
      ========================================================================== */
 
 /**
    * 1. Change the font styles in all browsers.
    * 2. Remove the margin in Firefox and Safari.
    */
 
 button {
     border: none;
     cursor: pointer;
    padding: 0;
 }
 
 /**
    * Correct the inability to style clickable types in iOS and Safari.
    */
 
 button,
 [type='button'],
 [type='reset'],
 [type='submit'] {
     -webkit-appearance: button;
 }
 
 /**
    * Remove the inner border and padding in Firefox.
    */
 
 button::-moz-focus-inner,
 [type='button']::-moz-focus-inner,
 [type='reset']::-moz-focus-inner,
 [type='submit']::-moz-focus-inner {
     border-style: none;
     padding: 0;
 }
 
 /**
    * Restore the focus styles unset by the previous rule.
    */
 
 button:-moz-focusring,
 [type='button']:-moz-focusring,
 [type='reset']:-moz-focusring,
 [type='submit']:-moz-focusring {
     outline: 1px dotted ButtonText;
 }
 
 /**
    * 1. Add the correct box sizing in IE 10.
    * 2. Remove the padding in IE 10.
    */
 
 [type='checkbox'],
 [type='radio'] {
     box-sizing: border-box; /* 1 */
     padding: 0; /* 2 */
 }
 
 /**
    * Correct the cursor style of increment and decrement buttons in Chrome.
    */
 
 [type='number']::-webkit-inner-spin-button,
 [type='number']::-webkit-outer-spin-button {
     height: auto;
 }
 
 /**
    * 1. Correct the odd appearance in Chrome and Safari.
    * 2. Correct the outline style in Safari.
    */
 
 [type='search'] {
     -webkit-appearance: textfield; /* 1 */
     outline-offset: -2px; /* 2 */
 }
 
 /**
    * Remove the inner padding in Chrome and Safari on macOS.
    */
 
 [type='search']::-webkit-search-decoration {
     -webkit-appearance: none;
 }
 
 /**
    * 1. Correct the inability to style clickable types in iOS and Safari.
    * 2. Change font properties to `inherit` in Safari.
    */
 
 ::-webkit-file-upload-button {
     -webkit-appearance: button; /* 1 */
     font: inherit; /* 2 */
 }
 
 /* Interactive
      ========================================================================== */
 
 /*
    * Add the correct display in Edge, IE 10+, and Firefox.
    */
 
 details {
     display: block;
 }
 
 /*
    * Add the correct display in all browsers.
    */
 
 summary {
     display: list-item;
 }
 
 /* Misc
      ========================================================================== */
 
 /**
    * Add the correct display in IE 10+.
    */
 
 template {
     display: none;
 }
 
 /**
    * Add the correct display in IE 10.
    */
 
 [hidden] {
     display: none;
 }
 