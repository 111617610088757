@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	.sidebar-icon {
		@apply relative flex items-center justify-center h-14 w-14
            mt-2 mb-2 mx-auto
            text-gray-400 hover:bg-red-500 hover:text-white
            rounded-3xl hover:rounded-xl transition-all duration-300
            ease-linear cursor-pointer;
	}
	.sidebar-active {
		@apply bg-red-500 text-white;
	}
	.sidebar-tooltip {
		@apply absolute w-auto p-2 m-2 min-w-max left-[4.5rem] rounded-md
            shadow-md text-gray-900 bg-white text-xs font-bold
            transition-all duration-100
            scale-0 origin-left z-30;
	}

	.message-tooltip {
		@apply absolute w-auto p-2 m-2 min-w-max bottom-[3.5rem] rounded-md
            shadow-md text-gray-900 bg-white text-xs font-bold
            transition-all duration-100
            scale-0 origin-left z-30;
	}

	.header-tooltip {
		@apply absolute w-auto p-2 m-2  min-w-max top-[3.5rem] rounded-md
            shadow-md text-gray-900 bg-white text-xs font-bold
            transition-all duration-100
            scale-0 origin-left z-30;
	}

	.fab {
		@apply w-16 h-16 rounded-full bg-health absolute bottom-32 
    left-[75%] items-center justify-center flex text-white 
    font-medium text-xs leading-tight uppercase shadow-2xl
      focus:bg-health focus:outline-none focus:ring-0
        transition duration-150 ease-in-out;
	}

	.fab-options {
		@apply absolute w-48 h-52 m-2 mb-12 min-w-max -left-36 -bottom-4 rounded-md
            transition-all duration-100 
            scale-0 origin-right z-30;
	}

	.fab-button {
		@apply w-full h-12 py-4 px-4 text-health  text-left rounded hover:bg-health-20 normal-case flex flex-row justify-start items-center space-x-2;
	}

	.gender {
		@apply form-select appearance-none
      px-4
      text-gray-700
	  w-full p-3
	  mt-2
	  mb-6
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
	  ring-0 ring-gray-300
      rounded
      transition
      ease-in-out
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none;
	}
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.emoji-picker {
	position: absolute;
	bottom: 60px;
	left: 0px;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
